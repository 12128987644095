import { SessionDto } from '@data-import/data-access/bulk-operations-api';

import { environment } from './environments/environment';

export function getPreferredOdpOrgId(session?: SessionDto): string {
  const switchContextId = new URLSearchParams(window.location.search).get(
    'contextSelector:switchContext',
  );
  const urlPathParam = window.location.pathname.split('/')[1] || '';

  let companyId = '';

  if (/^[1-9][0-9]*$/.test(switchContextId)) {
    companyId = switchContextId;
  } else if (/^[1-9][0-9]*$/.test(urlPathParam)) {
    companyId = urlPathParam;
  } else if (session?.lastUsedContextId) {
    companyId = session.lastUsedContextId.toString();
  } else if (session?.availableContexts?.[0]?.id) {
    companyId = session.availableContexts[0].id.toString();
  }

  return companyId;
}

export function redirectToLoginPage(): void {
  const odpOrgId = getPreferredOdpOrgId();
  const navigationPath = getPathWithoutOdpOrgId();
  let requestedPage = environment.loginRedirectUrl;
  requestedPage += odpOrgId ? '/' + odpOrgId : '';
  requestedPage += navigationPath ? '/' + navigationPath : '';

  window.location.href =
    `${environment.signinUrl}` +
    `?msgCode=NOTLOGGEDIN` +
    `&requestedPage=${encodeURIComponent(requestedPage)}`;
}

export function getPathWithoutOdpOrgId(): string {
  let urlPathSplits = window.location.pathname.split('/');

  if (urlPathSplits[1] && Number.isInteger(parseInt(urlPathSplits[1]))) {
    urlPathSplits = urlPathSplits.slice(2);
  } else {
    urlPathSplits = urlPathSplits.slice(1);
  }

  return urlPathSplits.join('/');
}

export function redirectToAuthenticationErrorPage(): void {
  window.history.replaceState(null, null, `/authentication-error`);
}

export function redirectToUnauthorizedPage(): void {
  window.history.replaceState(null, null, `/unauthorized`);
}

export function redirectToServiceUnavailablePage(): void {
  window.history.replaceState(null, null, `/service-unavailable`);
}

export function redirectFromUnauthorizedOrAuthenticationError(): void {
  const pathnameSplits = window.location.pathname.split('/');
  const popped = pathnameSplits.pop();

  if (['unauthorized', 'authentication-error'].includes(popped)) {
    window.history.replaceState(null, null, pathnameSplits.join('/'));
  }
}

export function removeQueryParam(param: string): void {
  const url = new URL(location.href);
  url.searchParams.delete(param);
  history.replaceState(null, null, url);
}
